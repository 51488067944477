*,
html,
body,
a,
p,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
html,
body {
  width: 100%;
  overflow-x: hidden;
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: left;
}
.btn__main {
  background: #f0c854 !important;
  color: #313131 !important;
  border-color: #f0c854 !important;
  border-radius: 10px !important;
  font-size: 15px !important;
  line-height: 22.5px;
  font-weight: 400;
  padding: 10px 35px !important;
  display: flex !important;
  align-items: center;
}
.container-fluid {
  padding: 0 60px !important;
}

@media (max-width: 700px) {
  .container-fluid {
    padding: 0 20px !important;
  }
}

.Nav__wrapper {
  position: relative;
  z-index: 999;
  width: 100%;
}
.Nav__wrapper .navbar-nav .nav-link.active {
  color: #f0c854 !important;
}

.Nav__wrapper .navbar-nav a {
  position: relative;
  padding: 10px 20px !important;
  color: #fff !important;
  font-size: 16px;
  font-weight: 400;
  text-align: end;
}
.navbar .btn__main.nav-link {
  color: #303030 !important;
  padding: 6px 28px !important;
  margin-left: 20px !important;
}

@media (max-width: 700px) {
  .navbar .btn__main.nav-link {
    margin-left: 200px !important;
  }
}

.hp__banner {
  position: relative;
  top: -360px;
  margin-bottom: -90px;
  height: 100%;
  overflow: hidden;
  margin-bottom: -360px;
}

.hp__banner img {
  width: 100%;
  height: 170vh;
}

/* done changes to quote_wrapper */
.quote__wrapper {
  background: #fff;
  padding: 20px;
  width: 40%;
  height: auto;
  position: absolute;
  top: 40%;
  right: 6%;
  z-index: 99;
  border-radius: 30px;
}

@media (max-width: 1100px) {
  .quote__wrapper {
    width: 100%;
    left: 1px;
    position: relative;
    border: 2px solid #ececec;
  }
}

.quote__wrapper img {
  width: auto;
  padding-right: 10px;
}
.quote__wrapper .btn-group label:nth-child(1) {
  width: 45%;
  margin-right: 10px;
}
.quote__wrapper .btn-group label {
  border-radius: 10px !important;
  background: #f0c854;
  border: 1px solid #f0c854;
  padding: 15px 10px;
}

.quote__wrapper .btn-group .btn-check[checked] + label {
  background: #fff !important;
  color: #006cb7;
  border-radius: 30px !important;
  border-color: #006cb7;
}
.quote__wrapper .btn-group .btn-check + label {
  background: #006cb7 !important;
  color: #fff;
  border-radius: 30px !important;
  border-color: #006cb7;
}
.quote__wrapper .form-control {
  border-radius: 6px !important;
}
.quote__wrapper .btn-group {
  width: 100%;
  background-color: #006cb7;
  border-radius: 30px;
  margin-bottom: 20px !important;
}
.quote__wrapper .input-group .form-control {
  border-radius: 6px 0 0 6px !important;
}
.quote__wrapper .input-group-text {
  border-radius: 0 6px 6px 0 !important;
}
.hp__banner img {
  position: relative;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 768px) {
  .hp__banner img {
    height: 150vh;
    object-fit: cover;
    object-position: center;
  }
}

.hp__banner .banner__text {
  position: absolute;
  top: 40%;
  left: 7%;
  width: 45%;
  z-index: 99;
}

@media (max-width: 700px) {
  .hp__banner .banner__text {
    width: 100%;
    padding: 5%;
    top: 10%;
    text-align: center;
  }
}

@media (max-width: 1080px) {
  .hp__banner .banner__text {
    width: 100%;
    left: 0;
    top: 25%;
    text-align: center;
  }
}

.hp__banner .banner__text h1 {
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 50px;
  font-size: 64px;
  font-weight: 500;
  line-height: 75px;
  letter-spacing: -0.02em;
}

@media (max-width: 700px) {
  .hp__banner .banner__text h1 {
    font-size: 44px;
  }
}

.hp__banner .banner__text p {
  font-size: 18px;
  color: #fff;
  font-weight: 300;
  text-align: center;
}
.hp__banner .banner__text h1 span {
  color: #ffb000;
}
.section__gap {
  padding: 80px 0;
}
.purple__text {
  color: #f0c854;
}
.section__title span {
  color: #f0c854;
}

@media (max-width: 700px) {
  .section__title span {
    font-size: 20px;
    line-height: 20px;
  }
}

.section__title {
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
  color: #f2b120;
}

@media (max-width: 700px) {
  .section__title {
    font-size: 25px;
    line-height: 40px;
  }
}

.small__title {
  color: #262626;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}
.pt-0 {
  padding-top: 0;
}

.threecolwithimage__wrapper .section__title {
  margin-bottom: 20px;
  text-align: left;
  color: #313131;
  margin-bottom: 40px;
}
.threecolwithimage__wrapper .section__title span:last-child {
  color: #006cb7;
}
.threecolwithimage__wrapper p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #181818;
}
.threecolwithimage__wrapper .threecolwithimage__unit {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  box-shadow: 0px 3px 5px 0px #ddd;
  border-radius: 16px;
  padding: 20px;
  flex: 0 0 auto;
  width: 30.33333333%;
  margin: 0 18px;
}
/* added media query for cards to make them responsive */
@media (max-width: 700px) {
  .threecolwithimage__wrapper .threecolwithimage__unit {
    width: 100%;
    margin: 0 0 20px 0;
  }
}
.threecolwithimage__wrapper .threecolwithimage__unit img {
  width: 100%;
  height: 120px;
  object-fit: contain;
  margin-bottom: 30px;
}

.threecolwithimage__wrapper .threecolwithimage__unit h4 {
  text-transform: uppercase;
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.009999999776482582px;
}

@media (max-width: 700px) {
  .threecolwithimage__wrapper .threecolwithimage__unit h4 {
    text-align: center;
  }
}

.threecolwithimage__wrapper .threecolwithimage__unit .rating__wrap i {
  background-color: #f0c854;
  padding: 5px;
  border-radius: 50%;
  margin-right: 10px;
}
.threecolwithimage__wrapper .threecolwithimage__unit .rating__wrap {
  text-align: left !important;
}
.threecolwithimage__wrapper .threecolwithimage__unit h5 {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}
.threecolwithimage__wrapper .threecolwithimage__unit .price-box button {
  padding: 14px 16px !important;
  font-size: 16px !important;
  font-weight: 600;
}
.threecolwithimage__wrapper .threecolwithimage__unit .price-box {
  text-align: center;
}
.threecolwithimage__wrapper .threecolwithimage__unit p {
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
}

@media (max-width: 700px) {
  .threecolwithimage__wrapper .threecolwithimage__unit p {
    text-align: center;
  }
}

.threecolwithimage__wrapper .threecolwithimagehover__unit:hover {
  background: #0f1321;
  transition: 0.6s ease all;
  color: #fff;
}
.threecolwithimage__wrapper .threecolwithimagehover__unit:hover p {
  transition: 0.6s ease all;
  color: #fff;
}
.threecolwithimage__wrapper .threecolwithimagehover__unit {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  border-radius: 10px;
  flex: 0 0 auto;
  width: 30.33333333%;
  margin: 30px 18px 0 18px;
  padding: 30px 50px;
  transition: 0.6s ease all;
}
.threecolwithimage__wrapper .threecolwithimagehover__unit img {
  width: 100%;
  height: 120px;
  object-fit: contain;
  margin-bottom: 30px;
}

.threecolwithimage__wrapper .threecolwithimagehover__unit h4 {
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.009999999776482582px;
  margin-bottom: 15px;
}
.custom__media {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  height: 120px;
  padding: 10px;
  align-items: center;
}
.custom__media .left__part {
  background-color: #f7e8ff;
  padding: 20px;
  border-radius: 8px;
}
.custom__media .left__part img {
  width: 40px;
}
.custom__media .right__part p {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}

.custom__media:hover {
  box-shadow: 0px 20px 9px -14px #80808061;
  transition: 0.6s all ease;
}
.custom__media:hover .left__part {
  background-color: #f0c854;
  transition: 0.6s all ease;
}
.custom__media:hover .left__part img {
  filter: brightness(0) invert(1);
  transition: 0.6s all ease;
}

.custom__media__plain {
  display: flex;
  justify-content: start;
  gap: 30px;
  height: 120px;
  padding: 10px;
  align-items: center;
}
.custom__media__plain .left__part {
  padding: 20px;
  border-radius: 8px;
}
.custom__media__plain .left__part img {
  width: 70px;
  height: 70px;
  padding: 10px;
  border-radius: 10px;
  background: #0d3de5;
  object-fit: contain;
}
.custom__media__plain .right__part h3 {
  font-size: 20px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #3a3a3a;
}
.custom__media__plain .right__part p {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}
.homepage-review .reviewer-info small {
  display: block;
}
.homepage-review .reviewer-info {
  text-align: left;
  height: 80px;
  gap: 30px;
  position: relative;
  top: -60px;
  left: 20px;
}
.homepage-review .reviewer-info img {
  width: 80px !important;
}

.homepage-review {
  background: #f7f7f7;
}
.homepage-review p.text-left {
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  margin-top: 20px;
  padding-left: 20px;
}

.homepage-review .reviewer-info h5 {
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-top: 15px;
  color: #0c111f;
}
.homepage-review .reviewer-info i {
  color: #f5b100;
}
.homepage-review .reviewitem {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.84);
  backdrop-filter: blur(11px);
}
.homepage-slider-desc .carousel-status {
  display: none;
}
.homepage-slider-desc .carousel .slide img {
  height: 500px;
  padding: 50px 0;
  object-fit: contain;
}
.control-dots,
.carousel-status {
  display: none;
}

footer {
  background: #1a0d08;
  padding-top: 55px;
  padding-bottom: 10px;
  color: #fff;
}
footer img {
  margin-bottom: 20px;
}
footer p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
footer p.text-left {
  text-align: left;
}
footer h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
footer .list-group-item {
  background-color: transparent;
  padding: 0;
  border: 0;
}
footer a,
footer a:hover {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  padding: 10px 0;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
}

footer .input-group-text {
  background: #fcfcfc;
  border: 0;
  border-radius: 10px;
}
footer .form-control {
  border: 0;
  border-radius: 10px;
  background: #fcfcfc;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
footer .input-group-text i {
  color: #828282;
}
footer .copyright {
  color: #d3d3d3;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: 30px;
}

.quote__wrapper form {
  padding: 20px;
}
.quote__wrapper .form-select,
.quote__wrapper .form-control {
  border: 0;
  border-radius: 0px;
  background: #ececec;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  height: 55px;
}

@media (max-width: 700px) {
  .quote__wrapper .form-select,
  .quote__wrapper .form-control {
    margin-top: 5%;
  }
}

.quote__wrapper .input-group-text {
  border: 0;
  border-radius: 0px;
}

@media (max-width: 700px) {
  .quote__wrapper .input-group-text {
    margin-top: 5%;
  }
}

.quote__wrapper .input-group-text i {
  color: #828282;
}
.btn__gradient {
  border: 0 !important;
  height: 55px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(
    90.97deg,
    #ffcd83 0.17%,
    rgba(68, 34, 85, 0.67) 100%
  ) !important;
}

.quote__wrapper .btn__main {
  margin: 0 auto;
  display: block;
  width: 300px;
}

.threecolwithimage__wrapper {
  position: relative;
}
.threecolwithimage__wrapper .nav-tabs {
  text-align: center;
  display: inline-flex;
  padding: 20px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 0;
  border-bottom: 0;
}

@media (max-width: 700px) {
  .threecolwithimage__wrapper .nav-tabs {
    padding: 0px;
    position: relative;
  }
}

.threecolwithimage__wrapper .nav-tabs .nav-item button {
  color: #333;
}
.threecolwithimage__wrapper .nav-tabs .nav-item button.active {
  background: #f0c854;
  color: #313131;
  padding: 14px 32px;
  background: #f0c854;
  border-radius: 32px;
}
.btn__ghost {
  background: #ffffff !important;
  border: 1px solid #ffe12e !important;
  border-radius: 145px !important;
  color: #222;
}

.section__title.text-left {
  text-align: left;
}
.homepage-slider-desc .carousel .slider-wrapper {
  background: #0c111f;
}
.homepage-slider-desc .carousel-root {
  position: relative;
  left: -17%;
}
.homepage-more-about {
  padding: 40px 80px;
  background: #0d3de5;
  border-radius: 24px;
  position: relative;
}
/* added media query to display responsively*/
@media (max-width: 700px) {
  .homepage-more-about {
    padding: 10px 20px;
    height: 280px;
  }
}
.homepage-more-about .btn-white {
  background: #fff;
  color: #0d3de5;
  border-radius: 10px;
  font-size: 16px;
  padding: 13px 20px;
  margin-right: 20px;
}

@media (max-width: 700px) {
  .homepage-more-about .btn-white {
    display: block;
    height: 60px;
    width: 100%;
    text-align: center;
  }
  .homepage-more-about .btn-ghost {
    display: block;
    height: 60px;
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
}

.homepage-more-about .btn-ghost {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
  padding: 13px 20px;
}

.homepage-more-about h4 {
  color: #fff;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 39px;
  line-height: 57px;
}

@media (max-width: 700px) {
  .homepage-more-about h4 {
    font-size: 20px;
    line-height: 30px;
  }
}

.homepage-more-about:before {
  content: url("../src/images/dots.png");
  position: absolute;
  right: 36.2%;
}
/* added media query to display image none in tab view */
@media (max-width: 800px) {
  .homepage-more-about:before {
    display: none;
  }
}
.homepage-more-about:after {
  content: "";
  width: 175px;
  height: 175px;
  background: #ff9446;
  position: absolute;
  right: 33%;
  top: 220px;
  border-radius: 50%;
  z-index: 1;
}
/* added media query to display image none in tab view */
@media (max-width: 800px) {
  .homepage-more-about:after {
    display: none;
  }
}
.homepage-more-about a {
  text-decoration: none;
}
.homepage-more-about i {
  font-size: 54px;
  text-align: right;
  color: #fff;
  display: block;
}
.homepage-more-about img.float {
  position: absolute;
  right: 12%;
  top: -15%;
  width: 350px;
  border-radius: 10px;
  z-index: 2;
}
/* added media query to display image none in tab view */
@media (max-width: 800px) {
  .homepage-more-about img.float {
    display: none;
  }
}

.homepage-review .carousel .slide {
  margin-top: 40px;
}
footer .suscribe-part {
  text-align: center;
}
footer .suscribe-part p {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
/* added for responsive size */
@media (max-width: 700px) {
  footer .suscribe-part p {
    width: 100%;
  }
}

footer .social-icons a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #ddd;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 24px;
}
.w-80 {
  width: 100%;
  margin: 0 auto !important;
}
.threecolwithimagehoverflip__unit {
  width: 28.333333% !important;
  margin: 0 auto;
}
/* added media query for cards to make them responsive */
@media (max-width: 700px) {
  .threecolwithimagehoverflip__unit {
    width: 100% !important;
  }
}
.threecolwithimagehoverflip__unit .hoverable_content {
  position: relative;
  margin: 0 auto;
  padding: 0;
  border-radius: 10px;
}
.threecolwithimagehoverflip__unit img {
  width: 100%;
  border-radius: 10px;
}

.threecolwithimagehoverflip__unit .counter {
  background-color: #ffb000;
  position: absolute;
  bottom: -8px;
  color: #fff;
  padding: 10px;
  border-radius: 0 0 0 10px;
}
.threecolwithimagehoverflip__unit .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #006cb7;
  padding: 30px;
  border-radius: 10px;
  z-index: 999;
}
.threecolwithimagehoverflip__unit .overlay h2 {
  color: #fff;

  font-weight: 600;
  font-size: 76px;
  position: absolute;
  top: 0;
  left: 0;
  text-transform: uppercase;

  color: #ffffff;
}
.threecolwithimagehoverflip__unit .overlay h4:after {
  content: "";
  position: absolute;
  width: 100px;
  left: 50%;
  top: 37%;
  transform: translate(-50%, -50%);
  height: 1px;
  background-color: #fff;
}
.threecolwithimagehoverflip__unit .overlay h4 {
  color: #fff;
  margin-top: 70px;
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
}
.threecolwithimagehoverflip__unit .overlay p {
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  text-align: center;

  color: #ffffff;
}
.threecolwithimagehoverflip__unit h4 {
  text-transform: uppercase;
  margin-top: 15px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: #010101;
}
.threecolwithimagehoverflip__unit:hover .overlay {
  opacity: 1;
}

.App__section .section__title {
  color: #1b1a1b !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.contact__page a {
  color: #f58309;
  text-decoration: none;
  font-weight: 700;
}
.inr__banner {
  position: relative;
  top: -100px;
  margin-bottom: -100px;
}
.inr__banner img {
  width: 100%;
}
.contact-form button {
  margin-top: 20px;
}
.contact-form input {
  width: 100%;
  height: 50px;
}

/* Accordion styles */
.faq__page .tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.faq__page .tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.faq__page .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #2c3e50;
  font-weight: bold;
  cursor: pointer;
}
.faq__page .tab-label:hover {
  background: #1a252f;
}
.faq__page .tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.faq__page .tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}
.faq__page .tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.faq__page .tab-close:hover {
  background: #1a252f;
}

.faq__page input:checked + .tab-label {
  background: #1a252f;
}
.faq__page input:checked + .tab-label::after {
  transform: rotate(90deg);
}
.faq__page input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}
.faq__page input[name="rd"] {
  visibility: hidden;
}
.two_col_list .list-group-item {
  width: 50%;
  display: inline-block;
}

@media (max-width: 700px) {
  .two_col_list .list-group-item {
    width: 100%;
  }
}
.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}
.three_col_list .list-group-item {
  width: 32%;
  display: inline-block;
  border: 0;
}
.four_col_list .list-group-item {
  width: 25%;
  display: inline-block;
  border: 0;
}
.two_col_list,
.three_col_list,
.four_col_list {
  display: block !important;
}

.area__page a {
  color: #0c4b85 !important;
  text-decoration: none !important;
  font-weight: 600;
}

.text-color {
  color: #0c4b85 !important;
}

@media (max-width: 1199px) {
  .Nav__wrapper .navbar-nav a {
    padding: 10px 14px !important;
  }
  .hp__banner img {
    width: 100%;
    height: 170vh;
  }
  .hp__banner .banner__text {
    padding: 0 40px;
  }
  .hp__banner .banner__text h1 {
    margin-top: 40px;
    font-size: 44px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit {
    width: 28.333333%;
  }
  .homepage-more-about img.float {
    right: 4%;
    top: -5%;
    width: 300px;
  }
  .threecolwithimage__wrapper .section__title {
    font-size: 26px;
    font-weight: 700;
    line-height: 44px;
  }
}

@media (max-width: 991px) {
  .Nav__wrapper {
    background: #1e1e1e;
  }
  .navbar-nav {
    align-items: end;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit {
    width: 31.333333%;
    margin: 0 4px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit h4 {
    font-size: 16px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit .price-box button {
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  .homepage-more-about h4 br {
    display: none !important;
  }
  .suscribe-part .section__title {
    font-size: 28px;
    font-weight: 700;
    line-height: 46px;
    text-align: left !important;
  }
  footer .suscribe-part p {
    width: 100%;
  }
  .inr__banner {
    position: relative;
    top: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .threecolwithimagehoverflip__unit img {
    width: 100%;
    border-radius: 10px;
    height: 300px;
    object-fit: cover;
  }
  .threecolwithimagehoverflip__unit h4 {
    margin-bottom: 40px;
  }
  .homepage-review .section__title.text-left {
    text-align: center !important;
  }
  .homepage-review .section__title.text-left span {
    font-size: 25px;
    color: #f2b120;
  }
  .homepage-review .section__title.text-left br {
    display: none;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit {
    width: 100% !important;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 640px) {
  .hp__banner .banner__text h1 {
    margin-top: 0;
    font-size: 24px;
    line-height: 30px;
  }
  .three_col_list .list-group-item{
    width: 100%;
  }
  .four_col_list .list-group-item {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .three_col_list .list-group-item,
  .four_col_list .list-group-item {
    width: 100%;
  }
}
